// N等分数组 [1,2,3,4,5,6] => [[1,2,3], [4,5,6]]
export const splitArray = (ary, num = 4) =>
  ary.reduce((memo, curt) => {
    const index = memo.length
    if (index === 0) {
      memo[0] = [curt]
    } else if (memo[index - 1].length === num) {
      memo[index] = [curt]
    } else {
      memo[index - 1].push(curt)
    }
    return memo
  }, [])
