import React from "react"

import { Row, Col } from "react-bootstrap"
import { splitArray } from "../../utils/fp"
import BasicLayout from "../../layouts/basic-layout"

const data = [
  "http://cdn.jerryshi.com/gtkj/20200103153755.png",
  "http://cdn.jerryshi.com/gtkj/20200103154655.png",
  "http://cdn.jerryshi.com/gtkj/20200103154656.png",
  "http://cdn.jerryshi.com/gtkj/20200103154657.png",
  "http://cdn.jerryshi.com/gtkj/20200103154658.png",
  "http://cdn.jerryshi.com/gtkj/20200103154659.png",
]

const Idea = () => (
  <BasicLayout
    title="关于我们"
    bannerImg="http://cdn.jerryshi.com/gtkj/20200103174254.jpg"
  >
    {splitArray(data, 3).map((items, idx) => (
      <Row key={idx}>
        {items.map(src => (
          <Col
            key={src}
            md={4}
            sm={12}
            style={{ padding: 50, paddingBottom: 20, paddingTop: 30 }}
          >
            <img
              style={{ width: "100%", height: "100%" }}
              src={src}
              alt="idea"
            />
          </Col>
        ))}
      </Row>
    ))}
  </BasicLayout>
)

export default Idea
